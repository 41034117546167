.menuButton {
  margin-right: 2;
}

.title {
  flex-grow: 1;
}

.footer {
  bottom: 0;
  width: 100%;
  height: 10%;
  position: sticky;
}
